import './index.scss';
import { useEffect, useState } from 'react';
import { Empty } from 'antd';
import { Crate } from '../../components/Crate';
import { CustomPagination } from '../../components/CustomPagination';

import { getPagedCrates } from '../../services/CrateService';

const VIEWABLE_SIZE = 50;

export const Crates = () => {

    const [ crates, setCrates ] = useState();
    const [ page, setPage ] = useState({
        viewableSize: VIEWABLE_SIZE, 
        totalSize: 0, 
        index: 0, 
        startRange: 1, 
        endRange: 1
    });

    useEffect(() => {

        const fetchCrates = async () => {
            const data = await getPagedCrates(page);
            setCrates([...data.items]);
            setPage({...data.page});
        }

        fetchCrates();
    
    }, []);

    const changePage = () => {

    }

    return <div className="crates-container">

        <h1>Crates</h1>
        {crates?.length > 0 && <>
            <div className="crate-list">
                {crates?.map((item, index) => <Crate key={index} crate={item}/>)}
            </div>
            <CustomPagination page={page} changePage={changePage}/>
        </>
        || <Empty description="No Crates Available"/>}
    </div>
}