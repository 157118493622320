import './index.scss';

import { useEffect, useState } from 'react';
import { Modal, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { VideoList } from '../../components/VideoList';

import { getDownloadInfo, getDownloadPermissions, getTrendingVideos } from '../../services/VideoService';

import { setPlayerStatus, setPreview } from '../../reducers/PlayerReducer';
import { selectCurrentUser, setUser } from '../../reducers/AuthReducer';

export const Trending = () => {

    const tabItems = [
        {
            key: 'WEEK',
            label: 'Top 100 for the Week'
        },
        {
            key: 'MONTH',
            label: 'Top 100 for the Month'
        },
        {
            key: 'YEAR',
            label: 'Top 100 for the Year'
        },
        {
            key: 'ALL_TIME',
            label: 'Top 100 All Time'
        },
    ]

    const [ preDownloadOpen, setPreDownloadOpen ] = useState();
    const [ permissions, setPermissions ] = useState();
    const [ videos, setVideos ] = useState([]);
    const [ video, setVideo ] = useState();
    
    const dispatch = useDispatch();

    const user = useSelector(selectCurrentUser);

    useEffect(() => {

        const fetchTrendingVideos = async () => {
            const data = await getTrendingVideos(user, 'WEEK');
            const transformed = data.map((item) => item.video);
            setVideos([...transformed]);
        }

        fetchTrendingVideos();

    }, []);

    const fetchVideos = async (period) => {
        const data = await getTrendingVideos(user, period);
        const transformed = data.map((item) => item.video);
        setVideos([...transformed]);
    }

    const changeTab = (key) => {
        fetchVideos(key);
    }

    const changePage = () => {

    }

    const playPreview = (video) => {
        dispatch(setPlayerStatus(true));
        dispatch(setPreview(video));
    }

    const downloadVideo = async (video) => {

        const permissions = await getDownloadPermissions(user.id, video.id);
        setPermissions(permissions);
        setVideo(video);
        setPreDownloadOpen(true);
    }

    const onDownload = async () => {
        setPreDownloadOpen(false);
        if (permissions?.code !== 2) {
            const info = await getDownloadInfo(user.id, video.id);
            dispatch(setUser(info.user));
            window.open(info.downloadLink, "_blank", "noreferrer");

            let ndx = videos.findIndex((vid) => vid.id === video.id);
            if (ndx >= 0) {
                const video = {...videos[ndx], downloadCount: videos[ndx].downloadCount + 1};
                videos.splice(ndx, 1, video);
            }
        }
    }

    return <div className="trending-container">
        <h1>Trending</h1>
        <div className="tabs">
            <Tabs items={tabItems} onChange={changeTab}></Tabs>
        </div>
        <VideoList 
            videos={videos}
            playPreview={playPreview}
            downloadVideo={downloadVideo}/>

        <Modal 
            title="Download Confirmation" 
            open={preDownloadOpen} 
            onOk={onDownload} 
            onCancel={() => setPreDownloadOpen(false)}
            cancelButtonProps={{ style: { display: permissions?.code !== 2 ? 'inline' : 'none' } }}>
            <p>{permissions?.message}</p>
        </Modal>
    </div>
}