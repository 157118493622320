import './index.scss';
import { Button, Col, DatePicker, Form, Input, notification, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineCheckCircle, AiOutlineCreditCard } from 'react-icons/ai';

import { getSubscriptionPlan, subscribe } from '../../services/SubscriptionService';

import { selectCurrentUser } from '../../reducers/AuthReducer';

import visaIcon from '../../assets/images/visa.png';
import masterCardIcon from '../../assets/images/mastercard.png';
import americanExpressIcon from '../../assets/images/amex.png';

const monthFormat = "MM/YY";

export const SubscriptionForm = () => {

    const [ isSuccess, setSuccess ] = useState(false);
    const [ cardNumber, setCardNumber ] = useState('');
    const [ cardType, setCardType ] = useState();
    const [ maxLength, setMaxLength ] = useState(19);
    const [ plan, setPlan ] = useState();
  
    const navigate = useNavigate();

    const user = useSelector(selectCurrentUser);
    const { planId } = useParams();

    const [ form ] = Form.useForm();

    useEffect(() => {

        const fetchSubscriptionPlan = async () => {
            const plan = await getSubscriptionPlan(planId);
            setPlan(plan);
        }

        fetchSubscriptionPlan();

    }, []);

    const formatCardNumber = (value) => {

        value = value.replace(/\s/g, ""); // Remove existing spaces
        let formattedValue = "";
    
        if (value.startsWith("4")) {
          // Visa
            for (let i = 0; i < value.length; i++) {
                if (i > 0 && (i === 4 || i === 8 || i === 12)) {
                formattedValue += " "; // Add a space after every 4 characters
                }
                formattedValue += value[i];
            }
            setMaxLength(19);
            setCardType("visa");

        } else if (value.match(/^5[1-5]/)) {

          // Mastercard
            for (let i = 0; i < value.length; i++) {
                if (i > 0 && i % 4 === 0) {
                formattedValue += " "; // Add a space after every 4 characters
                }
                formattedValue += value[i];
            }
            setMaxLength(19);
            setCardType("masterCard");

        } else if (value.match(/^3[47]/)) {
          
            // American Express
            for (let i = 0; i < value.length; i++) {
                    if (i === 4 || i === 10) {
                        formattedValue += " "; // Add a space after the 4th and 10th characters
                    }
                    formattedValue += value[i];
            }
            setMaxLength(17);

            setCardType("americanExpress");

        } else {

            // No recognized card type
            for (let i = 0; i < value.length; i++) {
                if (i > 0 && i % 4 === 0) {
                formattedValue += " "; // Add a space after every 4 characters
                }
                formattedValue += value[i];
            }
            setMaxLength(19);
            setCardType(null);
        }
    
        return formattedValue;
      };
    
      const handleCardNumberChange = (e) => {
        const { value } = e.target;
        const formattedValue = formatCardNumber(value);
        setCardNumber(formattedValue);
      };
    
      const disabledDate = (current) => {
        // return current && current < moment().endOf("day");
      };
    
      const cvcRules = (cardType) => {
        const rules = [
          {
            required: true,
            message: "Please enter CVC",
          },
          {
            whitespace: true,
          },
        ];
    
        if (cardType === "americanExpress") {
          rules.push({
            pattern: /^\d{4}$/,
            message: "CVC must be a 4-digit number",
          });
        } else {
          rules.push({
            pattern: /^\d{3}$/,
            message: "CVC must be a 3-digit number",
          });
        }
    
        return rules;
      };

    const subscribeNow = async (value) => {

        try {

            const creditCard = {...value, user: user};
            const info = {
                creditCard,
                plan
            }

            const response = await subscribe(info);
            if (!response.error) {
                setSuccess(true);
            } else {
                notification.error({
                    message: "Subscription Failed!", 
                    description: "An error occurred while trying to subscribe to this plan."
                });
            }

        } catch (e) {
            notification.error({
                message: "Subscription Failed!", 
                description: "An error occurred while trying to subscribe to this plan."
            });
        }
    }

    const backToHome = () => {
        navigate('/main/home');
    }
    
    return <div className="subscription-form-container">

        {!isSuccess 
        && <div className="card-form">
            <div className="header">
                Subscription Plan
            </div>
            <div className="subscription-plan">
                <span className="name">{plan?.name}</span>
                <span className="price">${plan?.price.toFixed(2)}</span>
            </div>
            <div className="header">
                Credit Card Form
            </div>
            <Form 
                form={form} 
                className="form"
                onFinish={subscribeNow}>
                <label>Card Number *</label>
                <Form.Item
                    name="cardNumber"
                    rules={[
                    {
                        required: true,
                        message: "Card number is required",
                    },
                    {
                        whitespace: true,
                    },
                    ]}
                    getValueFromEvent={(e) =>
                        formatCardNumber(e.target.value.replace(/\s/g, ""))
                    } // Format value on form submission
                >
                    <Input
                        placeholder="Enter card number"
                        value={cardNumber}
                        onChange={handleCardNumberChange}
                        maxLength={maxLength} // Allow maximum 19 characters (including spaces)
                        suffix={
                            cardType === "visa" ? (
                            <img src={visaIcon} alt="visa logo" />
                            ) : cardType === "masterCard" ? (
                            <img src={masterCardIcon} alt="mastercard logo" />
                            ) : cardType === "americanExpress" ? (
                            <img src={americanExpressIcon} alt="american express logo" />
                            ) : (
                            <AiOutlineCreditCard size={24}/>
                            )
                        }/>
                </Form.Item>
                <label>Name of Card Holder *</label>
                <Form.Item 
                    name="firnamestName"
                    rules={[
                        {
                        required: true,
                        message: "Name is required.",
                        },
                        {
                        whitespace: true,
                        },
                    ]}>
                    <Input/>
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <label>Expiration Date *</label>
                        <Form.Item name="expiryDate">
                        <DatePicker
                            style={{width: '100%'}}
                            format={monthFormat}
                            picker="month"
                            placeholder={monthFormat}
                            disabledDate={disabledDate}
                        />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <label>CVC *</label>
                        <Form.Item name="cvc" rules={cvcRules(cardType)}>
                        <Input
                            placeholder="CVC"
                            maxLength={cardType === "americanExpress" ? 4 : 3}
                        />
                        </Form.Item>
                    </Col>
                </Row>
                <br/>
                <Form.Item shouldUpdate className="submit">
                {() => (
                    <Button
                        block
                        type="primary"
                        htmlType="submit"
                    >
                        Subscribe Now!
                    </Button>
                )}
                </Form.Item>
            </Form>
        </div>
        || <div className="success-panel">
            <div className="congratulatory-header">
                Congratulations! 
            </div>
            <AiOutlineCheckCircle color={'lightgreen'} size={120}/>
            <br/>
            <br/>
            <div className="congratulatory-message">
                You are now a subscribed member
            </div>
            <div className="congratulatory-message">
                <span className="plan">{plan?.name}</span>
            </div>
            <div className="button-container">
                <Button type="primary" onClick={backToHome}>
                    <span className="button">Back to Home</span>
                </Button>
            </div>
        </div>
        }
    </div>
}
