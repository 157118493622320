import './index.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { VideoList } from '../../components/VideoList';

import { getDownloadInfo, getDownloadPermissions, getLatestVideos } from '../../services/VideoService';

import { setPlayerStatus, setPreview } from '../../reducers/PlayerReducer';
import { selectCurrentUser, setUser } from '../../reducers/AuthReducer';

const VIEWABLE_SIZE = 50;

export const NewReleases = () => {

    const [ preDownloadOpen, setPreDownloadOpen ] = useState();
    const [ permissions, setPermissions ] = useState();
    const [ videos, setVideos ] = useState();
    const [ video, setVideo ] = useState();

    const dispatch = useDispatch();

    const user = useSelector(selectCurrentUser);

    useEffect(() => {

        const fetchLatestVideos = async () => {
            const videos = await getLatestVideos(user);
            setVideos([...videos]);
        }

        fetchLatestVideos();
    
    }, []);

    const playPreview = (video) => {
        dispatch(setPlayerStatus(true));
        dispatch(setPreview(video));
    }

    const downloadVideo = async (video) => {

        const permissions = await getDownloadPermissions(user.id, video.id);
        setPermissions(permissions);
        setVideo(video);
        setPreDownloadOpen(true);
    }

    const onDownload = async () => {
        setPreDownloadOpen(false);
        if (permissions?.code !== 2) {
            const info = await getDownloadInfo(user.id, video.id);
            dispatch(setUser(info.user));
            window.open(info.downloadLink, "_blank", "noreferrer");

            let ndx = videos.findIndex((vid) => vid.id === video.id);
            if (ndx >= 0) {
                const video = {...videos[ndx], downloadCount: videos[ndx].downloadCount + 1};
                videos.splice(ndx, 1, video);
            }
        }
    }

    return <div className="new-releases-container">
        <h1>New Releases</h1>
        <VideoList 
            videos={videos}
            playPreview={playPreview}
            downloadVideo={downloadVideo}/>

        <Modal 
            title="Download Confirmation" 
            open={preDownloadOpen} 
            onOk={onDownload} 
            onCancel={() => setPreDownloadOpen(false)}
            cancelButtonProps={{ style: { display: permissions?.code !== 2 ? 'inline' : 'none' } }}>
            <p>{permissions?.message}</p>
        </Modal>

    </div>
}