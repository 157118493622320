import AppConfig from '../../config/AppConfig';
import { noAuthGet } from '../AuthorizationService';

const CRATES_URI = `${AppConfig.RESOURCE_URL}/api/v1/cdjv/crates`;

/**
 * Gets the crates to display for the home page.
 * 
 * @param {*} id the id of the crate to get
 * @returns the crate
 */
export const getFeaturedCrates = async () => {

    const URI = `${CRATES_URI}/featured`;
    const fetchedCrate = await noAuthGet(URI);
    return fetchedCrate;
}

/**
 * Gets the paged list of crates.
 * 
 * @param {*} id the id of the crate to get
 * @returns the crate
 */

export const getPagedCrates = async ({
    viewableSize, 
    action, 
    index, 
    totalSize, 
    search, 
    sortField, 
    ascending}) => {

    let URI = `${CRATES_URI}/paged?viewableSize=${viewableSize}&published=true`;

    if (action) URI += `&action=${action}`;
    if (index) URI += `&index=${index}`;
    if (totalSize) URI += `&totalSize=${totalSize}`;
    if (search) URI += `&search=${search}`;
    if (sortField) URI += `&sortField=${sortField}`;
    if (ascending) URI += `&ascending=${ascending}`;

    const pagedCrates = await noAuthGet(URI);
    return pagedCrates;
}

/**
 * Gets a crate.
 * 
 * @param {*} id the id of the crate to get
 * @returns the crate
 */
export const getCrate = async (id) => {

    const URI = `${CRATES_URI}/${id}`
    const fetchedCrate = await noAuthGet(URI);
    return fetchedCrate;
}