import './index.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { VideoList } from '../../components/VideoList';

import { getCrateSet } from '../../services/CrateSetService';
import { getDownloadInfo, getDownloadPermissions } from '../../services/VideoService';

import { setPlayerStatus, setPreview } from '../../reducers/PlayerReducer';
import { selectCurrentUser, setUser } from '../../reducers/AuthReducer';

import AppConfig from '../../config/AppConfig';

const VIEWABLE_SIZE = 50;

export const CrateSet = () => {

    const { crateId } = useParams();

    const [ preDownloadOpen, setPreDownloadOpen ] = useState();
    const [ permissions, setPermissions ] = useState();
    const [ crate, setCrate ] = useState();
    const [ videos, setVideos ] = useState();
    const [ video, setVideo ] = useState();
    const [ image, setImage ] = useState();

    const dispatch = useDispatch();

    const user = useSelector(selectCurrentUser);

    useEffect(() => {

        const fetchCrateSet = async () => {        
            const set = await getCrateSet(crateId);
            setVideos([...set.videos]);
            setCrate({...set.crate});
            setImage(`${AppConfig.CRATE_CDN}/${set.crate.image}`);
        }

        fetchCrateSet();
    
    }, []);

    const playPreview = (video) => {
        dispatch(setPlayerStatus(true));
        dispatch(setPreview(video));
    }

    const downloadVideo = async (video) => {

        const permissions = await getDownloadPermissions(user.id, video.id);
        setPermissions(permissions);
        setVideo(video);
        setPreDownloadOpen(true);
    }

    const onDownload = async () => {
        setPreDownloadOpen(false);
        if (permissions?.code !== 2) {
            const info = await getDownloadInfo(user.id, video.id);
            dispatch(setUser(info.user));
            window.open(info.downloadLink, "_blank", "noreferrer");
        }
    }

    return <div className="crate-set-container">
        <div className="crate-info">
            <img src={image}/>
            <div className="info">
                <div className="name">{crate?.name}</div>
                <div className="count">{videos?.length} videos</div>
                <div className="description">{crate?.description}</div>
            </div>
        </div>
        <VideoList 
            videos={videos}
            playPreview={playPreview}
            downloadVideo={downloadVideo}/>

        <Modal 
            title="Download Confirmation" 
            open={preDownloadOpen} 
            onOk={onDownload} 
            onCancel={() => setPreDownloadOpen(false)}
            cancelButtonProps={{ style: { display: permissions?.code !== 2 ? 'inline' : 'none' } }}>
            <p>{permissions?.message}</p>
        </Modal>

    </div>
}