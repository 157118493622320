import React from "react";
import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";

import { Main } from "./pages/Main";
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { Home } from "./pages/Home";
import { NewReleases } from "./pages/NewReleases";
import { Trending } from "./pages/Trending";
import { Crates } from "./pages/Crates";
import { CrateSet } from "./pages/CrateSet";
import { Catalog } from "./pages/Catalog";
import { MyDownloads } from "./pages/MyDownloads";

import { ProtectedRoute } from "./components/ProtectedRoute";
import { ConfigProvider } from "antd";
import { Subscriptions } from "./pages/Subscriptions";
import { SubscriptionForm } from "./pages/SubscriptionForm";

const Context = React.createContext({ name: 'Default' });

const App = () => {

    return (  
        <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#FF914D",
                fontFamily: "'Rubik', sans-serif"
              },
            }}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main/>}>
                      <Route path="/main/home" element={<Home/>}/>
                      <Route path="/main/new-releases" element={<NewReleases/>}/>
                      <Route path="/main/trending" element={<Trending/>}/>
                      <Route path="/main/crates" element={<Crates/>}/>
                      <Route path="/main/crates/videos/:crateId" element={<CrateSet/>}/>
                      <Route path="/main/catalog" element={<Catalog/>}/>
                      <Route path="/main/my-downloads" element={<MyDownloads/>}/>
                      <Route path="/main/subscriptions" element={<Subscriptions/>}/>
                      <Route path="/main/subscription-form/:planId" element={<SubscriptionForm/>}/>
                    </Route>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    {/* <Route path="main/*" element={
                      <ProtectedRoute>
                        <Main/>
                      </ProtectedRoute>
                    }/> */}
                </Routes>
            </BrowserRouter>
        </ConfigProvider>
        
    )  
}

export default App;