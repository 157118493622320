import AppConfig from "../../config/AppConfig";

/**
 * 
 * @param {*} username 
 * @param {*} password 
 * @returns 
 */
export const authenticate = async (username, password) => {
    
    let formData = new URLSearchParams();
    formData.append('grant_type', 'password');
    formData.append('client_id', "cdjv-client");
    formData.append('client_secret', AppConfig.CLIENT_SECRET);
    formData.append('username', username);
    formData.append('password', password);

    let header = {

        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formData.toString(),
        method: 'POST'
    };

    var loginURI = AppConfig.KEYCLOAK_URL;
    
    try {

        let request = new Request(loginURI, header);
        let response = await fetch(request);
        if (response.status !== 200) {
            return null;
        }

        const token = await response.json();
        sessionStorage.setItem('access_token', token.access_token);
        localStorage.setItem('refresh_token', token.refresh_token);
        return token;
    
    } catch (e) {
        console.log(e);
        return null;
    }
}

/**
 * 
 * @param {*} uri 
 * @param {*} body 
 * @returns 
 */
export const authGet = async (uri, body) => {

    const header = {
        headers: {
            'Host': AppConfig.HOST,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
        },
        method: 'GET',
    };

    let response = null;
    
    try {
        
        const request = new Request(uri, header);
        response = await fetch(request);

        if (response.status === 401) {
            return await refreshToken(uri, body, authGet);
        } else {
            const returnValue = await response.json();
            return returnValue;    
        }
        
    } catch (e) {
        if (response.ok) {
            return null;
        } else {
            throw e;
        }
    }
}

/**
 * 
 * @param {*} uri 
 * @param {*} body 
 * @returns 
 */
export const authPost = async (uri, body) => {

    let header = {
        headers: {
            'Host': AppConfig.HOST,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
        },
        method: 'POST',
        body: body ? JSON.stringify(body) : null
    }

    if (!body) delete header.body;

    let response = null;

    try {

        const request = new Request(uri, header);
        response = await fetch(request);
        
        if (response.status === 401) {
            return await refreshToken(uri, body, authPost);
        } else {
            const returnValue = await response.json();
            return returnValue;    
        }

    } catch (e) {
        if (response.ok) {
            return null;
        } else {
            throw e;
        }
    }
}

/**
 * 
 * @param {*} uri 
 * @param {*} body 
 * @returns 
 */
export const authPut = async (uri, body) => {

    let header = {
        headers: {
            'Host': AppConfig.HOST,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
        },
        method: 'PUT',
        body: body ? JSON.stringify(body) : null
    }

    if (!body) delete header.body;

    let response = null;

    try {

        const request = new Request(uri, header);
        response = await fetch(request);
        
        if (response.status === 401) {
            return await refreshToken(uri, body, authPut);
        } else {
            const returnValue = await response.json();
            return returnValue;    
        }

    } catch (e) {
        if (response.ok) {
            return null;
        } else {
            throw e;
        }
    }
}

/**
 * 
 * @param {*} uri 
 * @param {*} body 
 * @returns 
 */
export const authDelete = async (uri, body) => {
    
    const header = {
        headers: {
            'Host': AppConfig.HOST,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
        },
        method: 'DELETE',
        body: body ? JSON.stringify(body) : null
    };

    if (!body) delete header.body;

    let response = null;

    try {
        
        const request = new Request(uri, header);
        response = await fetch(request);
        
        if (response.status === 401) {
            return await refreshToken(body, authDelete);
        } else {
            const returnValue = await response.json();
            return returnValue;    
        }
        
    } catch (e) {
        if (response.ok) {
            return null;
        } else {
            throw e;
        }
    }
}

/**
 * 
 * @param {*} uri 
 * @param {*} body 
 * @returns 
 */
export const authBinaryGet = async (uri, body) => {

    const header = {
        headers: {
            'Host': AppConfig.HOST,
            'Content-Type':  'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
        },
        responseType: 'arraybuffer',
        method: 'GET',
    };

    let response = null;
    
    try {
        
        const request = new Request(uri, header);
        response = await fetch(request);
        
        if (response.status === 401) {
            return await refreshToken(uri, body, authBinaryGet);
        } else {
            const returnValue = await response.arrayBuffer();
            return returnValue;    
        }
        
    } catch (e) {
        if (response.ok) {
            return null;
        } else {
            throw e;
        }
    }

    // fetch('https://tabrisjs.com/assets/public-content/img/iphone-cropped-small.png')
    // .then(response => checkStatus(response) && response.arrayBuffer())
    // .then(buffer => {
    //    console.log(buffer);
    //    new PNG({ filterType:4 }).parse( buffer, function(error, data) {
    //      var options = {  colorType: 0 }
    //      var out = PNG.sync.write(data, options);
    //      let base64Encoded =  _arrayBufferToBase64(out);
    //      imageView.image = { src:'data:image/png;base64,' + base64Encoded};
    //    });
    // })
    // .catch(err => console.error(err)); // Never forget the final catch!
}

/**
 * 
 * @param {*} uri 
 * @param {*} body 
 * @param {*} authFetch 
 * @returns 
 */
const refreshToken = async (uri, body, authFetch) => {

    console.log('[INFO] Refreshing Token');
    
    let refresh = localStorage.getItem('refresh_token');

    let formData = new URLSearchParams();
    formData.append('grant_type', 'refresh_token');
    formData.append('client_id', "cdjv-client");
    formData.append('client_secret', AppConfig.CLIENT_SECRET);
    formData.append('refresh_token', refresh);
    
    let header = {
        headers: {'Content-Type': 'application/x-www-form-urlencoded' },
        body: formData.toString(),
        method: 'POST'
    };

    var loginURI = AppConfig.KEYCLOAK_URL;

    try {

        let request = new Request(loginURI, header);
        let response = await fetch(request);

        if (!response.ok) {

            // window.location = '/';
            sessionStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');

            setTimeout(() => {
                window.location = '/login';
            }, 45000);

        } else {

            const token = await response.json();
            
            sessionStorage.setItem('access_token', token.access_token);
            localStorage.setItem('refresh_token', token.refresh_token)

            const data = await authFetch(uri, body);
            return data;
        }

    } catch (e) {
        return null;
    }
} 

/**
 * 
 * @param {*} uri 
 * @param {*} body 
 * @returns 
 */
export const noAuthGet = async (uri) => {

    const header = {
        headers: {
            'Host': AppConfig.HOST,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET',
    };

    let response = null;
    
    try {
        
        const request = new Request(uri, header);
        response = await fetch(request);
        
        const returnValue = await response.json();
        return returnValue;    
        
    } catch (e) {
        if (response.ok) {
            return null;
        } else {
            throw e;
        }
    }
}

// export const getSessionCredentialsInStorage = () => {
//     return () => {
//       const session = localStorage.getItem(CONSTANT.SESSION_CREDENTIALS);
//       return JSON.parse(session);
//     };
//   };
  
//   export const setSessionCredentialsToStorage = (session) => {
//     return () => {
//       let stringCredentials = JSON.stringify(session);
//       localStorage.setItem(CONSTANT.SESSION_CREDENTIALS, stringCredentials);
//     };
//   };
  
export const removeSessionCredentialsToStorage = () => {
    return () => {
        // sessionStorage.removeItem('access_token', token.access_token);
        // localStorage.removeItem('refresh_token', token.refresh_token);
    };
};