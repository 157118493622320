import AppConfig from '../../config/AppConfig';
import { authGet, noAuthGet } from '../AuthorizationService';

const VIDEOS_URI = `${AppConfig.RESOURCE_URL}/api/v1/cdjv/videos`;
const DOWNLOADS_URI = `${AppConfig.RESOURCE_URL}/api/v1/cdjv/downloads`;

/**
 * Gets the featured videos.
 * 
 * @returns the featured videos
 */
export const getFeaturedVideos = async () => {
    
    const token = sessionStorage.getItem('access_token');

    const URI = `${VIDEOS_URI}/featured`;
    const fearuredVideos = token ? await authGet(URI) : await noAuthGet(URI);
    return fearuredVideos;
}

/**
 * Gets the latest videos.
 * 
 * @returns the latest videos
 */
export const getLatestVideos = async (user) => {
    
    const token = sessionStorage.getItem('access_token');

    let URI = `${VIDEOS_URI}/latest`;
    const latestVideos = token ? await authGet(URI) : await noAuthGet(URI);
    return latestVideos;
}

/**
 * Gets the trending videos for the current period
 * 
 * @param {*} period the current period
 * @returns the trending videos
 */
export const getTrendingVideos = async (user, period) => {
    
    const token = sessionStorage.getItem('access_token');

    const URI = `${VIDEOS_URI}/trending/${period}`;
    const videos = token ? await authGet(URI) : await noAuthGet(URI);
    return videos;
}

/**
 * Gets the paged list of videos.
 * 
 * @param {*} the video filter information
 * @returns the video
 */
export const getPagedVideos = async (userId, {
    viewableSize, 
    action, 
    index, 
    totalSize, 
    title, 
    artist, 
    genre, 
    year, 
    search, 
    sortField, 
    ascending}) => {

     const token = sessionStorage.getItem('access_token');

    let URI = `${VIDEOS_URI}/paged?viewableSize=${viewableSize}`;

    if (userId) URI += `&userId=${userId}`;
    if (action) URI += `&action=${action}`;
    if (index) URI += `&index=${index}`;
    if (totalSize) URI += `&totalSize=${totalSize}`;
    if (title) URI += `&title=${title}`;
    if (artist) URI += `&artist=${artist}`;
    if (genre) URI += `&genre=${genre}`;
    if (year) URI += `&year=${year}`;
    if (search) URI += `&search=${search}`;
    if (sortField) URI += `&sortField=${sortField}`;
    if (ascending) URI += `&ascending=${ascending}`;

    const pagedVideos = token ? await authGet(URI) : await noAuthGet(URI);
    return pagedVideos;
}

/**
 * Gets the paged list of downloaded videos of the user.
 * 
 * @param {*} id the video filter information
 * @returns the video
 */
export const getMyDownloads = async (userId, {
    viewableSize, 
    action, 
    index, 
    totalSize, 
    title, 
    artist, 
    genre, 
    year, 
    search, 
    sortField, 
    ascending}) => {

    let URI = `${DOWNLOADS_URI}/paged?userId=${userId}&viewableSize=${viewableSize}`;

    if (action) URI += `&action=${action}`;
    if (index) URI += `&index=${index}`;
    if (totalSize) URI += `&totalSize=${totalSize}`;
    if (title) URI += `&title=${title}`;
    if (artist) URI += `&artist=${artist}`;
    if (genre) URI += `&genre=${genre}`;
    if (year) URI += `&year=${year}`;
    if (search) URI += `&search=${search}`;
    if (sortField) URI += `&sortField=${sortField}`;
    if (ascending) URI += `&ascending=${ascending}`;

    const pagedVideos = await authGet(URI);
    return pagedVideos;
}

/**
 * Gets a video.
 * 
 * @param {*} id the id of the video to get
 * @returns the video
 */
export const getVideo = async (id) => {

    const URI = `${VIDEOS_URI}/${id}`
    const fetchedVideo = await noAuthGet(URI);
    return fetchedVideo;
}

/**
 * Gets the download permissions of a video.
 * 
 * @param {*} userId the id of the user who could have downloaded the video
 * @param {*} videoId the id of the video.
 * @returns 
 */
export const getDownloadPermissions = async(userId, videoId) => {
    const URI = `${DOWNLOADS_URI}/permissions?userId=${userId}&videoId=${videoId}`
    const info = await authGet(URI);
    return info;
}

/**
 * Gets the download information of a video.
 * 
 * @param {*} userId the id of the user who could have downloaded the video
 * @param {*} videoId the id of the video.
 * @returns 
 */
export const getDownloadInfo = async(userId, videoId) => {
    const URI = `${DOWNLOADS_URI}/info?userId=${userId}&videoId=${videoId}`
    const info = await authGet(URI);
    return info;
}
