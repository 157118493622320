import AppConfig from '../../config/AppConfig';
import { authGet } from '../AuthorizationService';

const REGISTRATION_URI = `${AppConfig.RESOURCE_URL}/api/v1/cdjv/public/registration`;
const USERS_URI = `${AppConfig.RESOURCE_URL}/api/v1/cdjv/users`;

/**
 * Registers a user.
 * 
 * @param {*} user  the user to register
 * @returns the registered user
 */
export const registerUser = async (user) => {

    let data = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(user),
    }

    let response = null;

    try {
        const request = new Request(REGISTRATION_URI, data);
        response = await fetch(request);
        const returnValue = await response.json();
        return returnValue;    
    } catch (e) {
        if (response.ok) {
            return null;
        } else {
            throw e;
        }
    }
}

/**
 * Gets the currently logged in user.
 * 
 * @returns the currently logged in user
 */
export const getUser = async () => {

    const URI = `${USERS_URI}/me`;
    const user = await authGet(URI);
    return user;
}
