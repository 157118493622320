import './index.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SubscriptionPlan } from '../../components/SubscriptionPlan';

import { getSubscriptionPlans } from '../../services/SubscriptionService';

import creditCards from '../../assets/images/credit-cards.png';

const VIEWABLE_SIZE = 50;

export const Subscriptions = () => {

    const [ plans, setPlans ] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchSubscriptionPlans = async () => {
            const data = await getSubscriptionPlans();
            setPlans(data);
        }

        fetchSubscriptionPlans();

    }, []);

    const signup = (planId) => {
        navigate(`/main/subscription-form/${planId}`);
    }

    return <div className="subscriptions-container">
        <h3>Subscriptions</h3>
        <div className="subscriptions-list">
            {plans?.map((item, index) => <SubscriptionPlan key={index} plan={item} onSignup={() => signup(item.id)}/>)}
        </div>
        <div className="credit-cards">
            <img src={creditCards}/>
        </div>
    </div>
}