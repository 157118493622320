import './index.scss';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Button, Drawer, Layout, } from "antd";
import { CaretLeftFilled, CaretRightFilled } from "@ant-design/icons";

import { SideBarMenu } from '../../components/SideBarMenu';
import { Header } from '../../components/Header';

import { getUser } from '../../services/UserService';
import { setUser } from '../../reducers/AuthReducer';
import { selectPlayerStatus, setPlayerStatus } from '../../reducers/PlayerReducer';
import { VideoPlayer } from '../../components/VideoPlayer';

export const Main = () => {

    const [ collapsed, setCollapsed ] = useState(false);
    
    const navigate = useNavigate()
    const openPlayer = useSelector(selectPlayerStatus);
    const dispatch = useDispatch();
    const location = useLocation();
    
    useEffect(() => {

        const { pathname } = location;

        const getCurrentUser = async () => {
            const user = await getUser();
            dispatch(setUser(user));
        }

        const refresh = localStorage.getItem('refresh_token');
        if (refresh) {
            getCurrentUser();
        }
        
        if (pathname === '/') navigate('/main/home');

    }, []);
    
    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    const onPlayerClose = () => {
        dispatch(setPlayerStatus(false));
    }

    return (<>
        <Layout className="main-layout">
            <Layout.Header>
                <Header />
            </Layout.Header>
            <Layout.Content>
                <Layout style={{height: '100%'}}>
                    {/* SIDEBAR */}
                    <Layout.Sider
                        width={240}
                        breakpoint="lg"
                        collapsed={collapsed}
                        onCollapse={toggleSidebar}
                        trigger={null}
                    >
                        {/* EXPAND TOGGLE */}
                        <Button
                            type="primary"
                            className="sidebar-toggle"
                            onClick={toggleSidebar}>
                            {collapsed ? <CaretRightFilled /> : <CaretLeftFilled />}
                        </Button>

                        <SideBarMenu collapsed={collapsed} />

                    </Layout.Sider>

                    <Layout.Content>
                        <Outlet/>
                    </Layout.Content>

                </Layout>        
            </Layout.Content>
        </Layout>
        <Drawer
            placement={'bottom'}
            closable={false}
            onClose={onPlayerClose}
            open={openPlayer}
            style={{background: '#222', color: 'white'}}
            key={'player'}>
            <VideoPlayer />
        </Drawer>
    </>);
}
