import './index.scss';
import logo from '../../assets/images/cdjv-logo.png';
import sideImage from '../../assets/images/login-side.jpeg';
import { 
    Button, 
    Checkbox, 
    Form, 
    Input,
    notification,
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticate } from '../../services/AuthorizationService';
import { useDispatch } from 'react-redux';
import { setAccessToken, setRefreshToken, setUser } from '../../reducers/AuthReducer';
import { getUser } from '../../services/UserService';

export const Login = () => {

    const [ rememberMe, setRememberMe ] = useState();
    const [ isLoading, setLoading ] = useState();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ form ] = Form.useForm();

    /**
     * Gets the current user
     */
    const getCurrentUser = async () => {
        const user = await getUser();
        dispatch(setUser(user));
    }

    /**
     * Log in the the application.
     * 
     * @param {*} credentials the username and password
     */
    const login = async ({username, password}) => {

        try {

            const response = await authenticate(username, password);
            if (null != response) {
                dispatch(setAccessToken(response.access_token));
                dispatch(setRefreshToken(response.setRefreshToken));
                await getCurrentUser();
                navigate('/main/home');
                form.resetFields();
            } else {
                notification.error({
                    message: "Login Failed!", 
                    description: "Invalid username or password."
                });
            }
            // navigate('/main/home');
            // form.resetFields();
        } catch (e) {
            notification.error({
                message: "Login Failed!", 
                description: "An error occurred while logging in account."
            });
        }
        
    }

    const register = () => {
        navigate('/register');
    }

    const home = () => {
        navigate('/main/home');
    }
    
    return <div className="login-container">
        <img className="side-image" src={sideImage}/>
        <div className="login-form-container">
            <div className="login-form">
                <img className="logo" src={logo}/>
                <Form 
                    form={form} 
                    className="form"
                    onFinish={login}>
                    <label>Email</label>
                    <Form.Item 
                        name="username"
                        required
                        hasFeedback
                        rules={[
                            {
                              required: true,
                              message: "Email is required.",
                            },
                            {
                              whitespace: true,
                            },
                        ]}>
                        <Input/>
                    </Form.Item>
                    
                    <label>Password</label>
                    <Form.Item 
                        name="password"
                        required
                        hasFeedback
                        rules={[
                            {
                              required: true,
                              message: "Password is required.",
                            },
                            {
                              whitespace: true,
                            },
                        ]}>
                        <Input.Password/>
                    </Form.Item>

                    <div className="reset-line">
                        <div>
                            <Checkbox onChange={setRememberMe}>Remember Me</Checkbox>
                        </div>
                        <div>
                            <Button className="forgot-password" type="link">Forgot Password</Button>
                        </div>
                    </div>

                    <Form.Item shouldUpdate className="submit">
                    {() => (
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            disabled={isLoading}
                        >
                            Login
                        </Button>
                    )}
                    </Form.Item>

                    <div className="signup-line">
                        <div>Not a member?</div>
                        <Button className="signup" type="link" onClick={register}>
                            Signup now!
                        </Button>
                        <span>|</span>
                        <Button className="signup" type="link" onClick={home}>
                            Back to Home
                        </Button>
                    </div>

                </Form>
            </div>
        </div>
    </div>
}
