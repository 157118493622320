import './index.scss';
import { 
    Button, 
    Checkbox, 
    Form, 
    Input, 
    notification
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../services/UserService';

export const Register = () => {

    const [ agree, setAgree ] = useState(false);
    const [ isLoading, setLoading ] = useState();

    const navigate = useNavigate();

    const [ form ] = Form.useForm();

    const login = () => {
        navigate('/login');
    }

    const home = () => {
        navigate('/main/home');
    }

    const register = async (value) => {

        try {

            const user = {...value, userType: 'DEEJAY', enabled: true};
            const response = await registerUser(user);
            if (!response.error_message) {
                notification.success({
                    message: "Congratulations!", 
                    description: "You are now registered as a member of ClubDJVideos!"
                });
                navigate('/login');
            } else {
                notification.error({
                    message: "Registration Failed!", 
                    description: response.error_message
                });
            }
        } catch (e) {
            notification.error({
                message: "Registration Failed!", 
                description: "An error occurred while trying to register your account."
            });
        }
    }
    
    return <div className="register-form-container">
        <div className="register-form">

            <div className="header">
                <div className="signupto">Sign up to</div>
                <div className="clubdjvideos">CLUBDJVIDEOS</div>
            </div>

            <div className="login-line">
                <div>Already a member?</div>
                <Button className="login" type="link" onClick={login}>
                    Login
                </Button>
                <span>|</span>
                <Button className="login" type="link" onClick={home}>
                    Back to Home
                </Button>
            </div>

            <Form 
                form={form} 
                className="form"
                onFinish={register}
                >
                
                <label>Email *</label>
                <Form.Item 
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid email!',
                        },
                        {
                            required: true,
                            message: "Email is required.",
                        },
                        {
                            whitespace: true,
                        },
                    ]}>
                    <Input/>
                </Form.Item>
                
                <label>First Name *</label>
                <Form.Item 
                    name="firstName"
                    rules={[
                        {
                          required: true,
                          message: "First name is required.",
                        },
                        {
                          whitespace: true,
                        },
                    ]}>
                    <Input/>
                </Form.Item>

                <label>Last Name *</label>
                <Form.Item 
                    name="lastName"
                    rules={[
                        {
                          required: true,
                          message: "Last name is required.",
                        },
                        {
                          whitespace: true,
                        },
                    ]}>
                    <Input/>
                </Form.Item>

                <label>DJ Name</label>
                <Form.Item 
                    name="djname">
                    <Input/>
                </Form.Item>

                <label>Password *</label>
                <Form.Item 
                    name="password"
                    rules={[
                        {
                          required: true,
                          message: "Password is required.",
                        },
                        {
                          whitespace: true,
                          message: "White space characters are not allowed."
                        },
                    ]}>
                    <Input.Password/>
                </Form.Item>

                <label>Confirm Password *</label>
                <Form.Item 
                    name="confirmPassword"
                    rules={[
                        {
                            required: true,
                            message: "Confirm password is required.",
                        },
                        {
                            whitespace: true,
                            message: "White space characters are not allowed."
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                    ]}>
                    <Input.Password/>
                </Form.Item>

                <div className="agreement">
                    <Checkbox onChange={setAgree}>I agree to the Terms and Conditions and Privacy Policy</Checkbox>
                </div>

                <Form.Item shouldUpdate className="submit">
                {() => (
                    <Button
                        block
                        type="primary"
                        htmlType="submit"
                        disabled={!agree}
                    >
                        Register
                    </Button>
                )}
                </Form.Item>

            </Form>
        </div>
    </div>
}
