import './index.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { Search } from '../../components/Search';
import { CustomPagination } from '../../components/CustomPagination';
import { VideoList } from '../../components/VideoList';

import { getDownloadInfo, getDownloadPermissions, getPagedVideos } from '../../services/VideoService';

import { setPlayerStatus, setPreview } from '../../reducers/PlayerReducer';
import { selectCurrentUser, setUser } from '../../reducers/AuthReducer';

const VIEWABLE_SIZE = 50;

export const Catalog = () => {

    const [ preDownloadOpen, setPreDownloadOpen ] = useState();
    const [ permissions, setPermissions ] = useState();
    const [ videos, setVideos ] = useState();
    const [ video, setVideo ] = useState();
    const [ page, setPage ] = useState({
        viewableSize: VIEWABLE_SIZE, 
        totalSize: 0, 
        index: 0, 
        startRange: 1, 
        endRange: 1
    });

    const dispatch = useDispatch();

    const user = useSelector(selectCurrentUser);

    useEffect(() => {

        const getVideos = async () => {
            fetchVideos(page);
        }

        getVideos();
    
    }, []);

    const fetchVideos = async (page) => {
        const data = await getPagedVideos(user?.id, page);
        setVideos([...data.items]);
        setPage({...data.page});
    }

    const changePage = (index) => {
        const data = {...page, index};
        setPage(data); 
        fetchVideos(data);
    }

    const searchVideos = (text) => {
        const data = {...page, action: 'search', search: text};
        setPage(data); 
        fetchVideos(data);
    }

    const filterVideos = ({genre, minBpm, maxBpm, majorKeys, minorKeys}) => {
        const data = {...page, genreId: genre?.id, minBpm, maxBpm, majorKeys, minorKeys};
        setPage(data); 
        fetchVideos(data);
    }

    const playPreview = (video) => {
        dispatch(setPlayerStatus(true));
        dispatch(setPreview(video));
    }

    const downloadVideo = async (video) => {
        const permissions = await getDownloadPermissions(user.id, video.id);
        setPermissions(permissions);
        setVideo(video);
        setPreDownloadOpen(true);
    }

    const onDownload = async () => {

        setPreDownloadOpen(false);
        if (permissions?.code !== 2) {
            const info = await getDownloadInfo(user.id, video.id);
            dispatch(setUser(info.user));
            window.open(info.downloadLink, "_blank", "noreferrer");

            let ndx = videos.findIndex((vid) => vid.id === video.id);
            if (ndx >= 0) {
                const video = {...videos[ndx], downloadCount: videos[ndx].downloadCount + 1};
                videos.splice(ndx, 1, video);
            }
        }
    }

    return <div className="catalog-container">

        <Search search={searchVideos} filter={filterVideos}/>
        <h1>Video Catalog</h1>
        <VideoList 
            videos={videos}
            playPreview={playPreview}
            downloadVideo={downloadVideo}/>
        <CustomPagination page={page} changePage={changePage}/>

        <Modal 
            title="Download Confirmation" 
            open={preDownloadOpen} 
            onOk={onDownload} 
            onCancel={() => setPreDownloadOpen(false)}
            cancelButtonProps={{ style: { display: permissions?.code !== 2 ? 'inline' : 'none' } }}>
            <p>{permissions?.message}</p>
        </Modal>
    </div>
}