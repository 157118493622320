let AppConfig = {
    // HOST: '192.168.100.15',
    // KEYCLOAK_URL: 'http://192.168.100.15:8080/auth/realms/clubdjvideos/protocol/openid-connect/token',
    // RESOURCE_URL: 'http://192.168.100.15:8080',
    // CLIENT_SECRET: '8hIcvcvu1AmxO0SkRAdt1vEfMO0VF7Es',
    HOST: 'https://api.clubdjvideos.net',
    KEYCLOAK_URL: 'https://api.clubdjvideos.net/auth/realms/clubdjvideos/protocol/openid-connect/token',
    RESOURCE_URL: 'https://api.clubdjvideos.net',
    CLIENT_SECRET: 'idunqATPLVSGUPp3ea5a7u5SoaDnHExQ',
    VERSION: 'w0.0.1',
    isHTTPS: false,
    CRATE_CDN: 'https://d2kbvfh8zvsdbd.cloudfront.net',
    PREVIEW_CDN: 'https://d3j9o9vyj25r64.cloudfront.net',
}

if (process.env.NODE_ENV === 'production') {
    AppConfig.HOST = 'https://api.clubdjvideos.net';
    AppConfig.KEYCLOAK_URL = 'https://api.clubdjvideos.net/auth/realms/clubdjvideos/protocol/openid-connect/token';
    AppConfig.RESOURCE_URL = 'https://api.clubdjvideos.net';
    AppConfig.CLIENT_SECRET = 'idunqATPLVSGUPp3ea5a7u5SoaDnHExQ';
    AppConfig.isHTTPS = false;
}

export default AppConfig;